<template>
  <section class="main">
      <PageTitle title="Beställ tårta med eget motiv" />
      <section class="dark red">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <img class="scale frame light" src="https://res.cloudinary.com/dagmarcarlssons/image/upload/v1426957938/slider/tartamedbild.jpg">
            </div>
            <div class="col-md-8 col-sm-8 col-xs-12">
              <h2>Tårtor med eget motiv</h2>
              <p>För att göra en tårtbeställning med eget motiv behöver du bara följa 3 enkla steg nedanför.<br>Om du har några frågor eller funderingar är det bara att höra av dig till oss, så ska vi försöka hjälpa dig på bästa möjliga sätt.</p>
              <router-link to="/kontakta-oss" class="read-more light">kontakta oss om du har några frågor eller funderingar.</router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="content-area light">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-sm-12 col-xs-12">
              <div class="order">
                <div class="step-1">
                  <h1 class="underline">1. Välj en befintlig bild eller ladda upp din egna.&nbsp;<span v-if="step > 1" class="fa fa-check green"></span></h1>
                  <div class="row row-eq-height" v-if="step === 1">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 vertical-separator">
                      <h3 class="underline">Befintliga bilder.</h3>
                      <p>Vi har sparat populära bilder som tidigare kunder beställt. Klicka på bilden för att välja den och gå vidare.</p>
                      <p>Just nu finns det inga sparade motiv.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <h3 class="underline">Ladda upp bild.</h3>
                      <p>Tryck på välj fil nedanför för att välja den bild du vill ladda upp.</p><p class="small">Har du några frågor är det bara att höra av dig så ska vi försöka hjälpa till på bästa möjliga sätt.</p>
                      <p>Tänk på följande:</p>
                      <ol>
                        <li>Bilden måste vara minst 800x800 pixlar.</li>
                        <li>Bildens filstorlek får inte vara större än 5mb.</li>
                        <li>Giltiga bildformat är png och jpg.</li>
                        <li>Använd en högupplöst bild för bästa resultat.</li>
                      </ol>
                      <input type="file" @change="loadImage">
                      <p class="red"></p>
                    </div>
                  </div>
                </div>
                <div class="step-2" v-if="coverImage">
                  <h1 class="underline">2. Beskär bilden så som du vill ha den.&nbsp;<span v-if="step > 2" class="fa fa-check green"></span></h1>
                  <div class="row row-eq-height" v-if="step === 2">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 vertical-separator" ref="cropcover">
                      <div ref="croppie"></div>
                      <p class="text-center">
                        <button>Rotera bilden <i class="fa fa-repeat" aria-hidden="true"></i></button>
                      </p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <p>Du kan zooma in genom att dra i reglaget under bilden. Genom att dra i bilden kan du positionera den så som du vill ha den.<br /><br /> När du känner dig nöjd trycker du beskära bilden och gå vidare. Är du inte helt nöjd kan du välja att ladda upp en ny bild.</p>
                      <button @click="resetImage" class="return">Gå tillbaka och ladda upp en ny bild</button>
                      <button @click="crop" class="call-to-action">Beskär bilden och gå vidare</button>
                    </div>
                  </div>
                </div>
                <div class="step-3" v-if="croppedImage">
                  <h1 class="underline">3. Verifiera bilden och ange kontaktuppgifter.&nbsp;<span class="fa fa-times red" v-if="step > 3 && messageSent && !isValid"></span><span v-if="step > 3 && messageSent && isValid" class="fa fa-check green"></span></h1>
                  <div class="row row-eq-height" v-if="step === 3">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 vertical-separator text-center">
                      <img :src="croppedThumb" width="300" />
                      <button @click="modifyCrop" class="return">Gå tillbaka och Beskär på nytt</button>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <order-form :submit="orderSubmit" />
                    </div>
                  </div>
                  <div class="row" v-if="sendingOrder">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="la-ball-scale-pulse la-2x">
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="messageSent">
                    <div class="col-md-6 col-md-offset-3" v-if="isValid">
                      <div class="response-body">
                        <h1>Din beställning har nu blivit skickad!</h1>
                        <p>Så fort vi får tid att behandla din beställning kommer vi kontrollera och godkänna den. Efter det kommer vi kontakta dig antingen via telefon eller mail med en bekräftelse.</p>
                        <p>Är det något du undrar över så är det bara att höra av dig, så ska vi försöka hjälpa dig på bästa möjliga sätt.</p>
                        <p class="info">Med vänliga hälsningar,<br>Dagmar Carlssons Hembageri Eftr.</p>
                        <p class="text-center">
                          <button @click="restart">Klicka här om du vill göra en ny beställning</button>
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 col-md-offset-3" v-if="!isValid">
                      <div class="response-body">
                        <h1>Något blev fel!</h1>
                        <p>Vi kunde inta ta emot din beställning. Ladda om sidan och försök igen. Skulle det då inte fungera ber vi dig kontakta oss, så ska vi försöka hjälpa dig på bästa möjliga sätt.</p>
                        <p class="info">Med vänliga hälsningar,<br>Dagmar Carlssons Hembageri Eftr.</p>
                        <p class="text-center">
                          <button @click="refreshPage">Ladda om sidan</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <canvas width="1654px" height="2339" id="cakeCoverCanvas" />
    </section>
</template>

<script>
import debounce from 'lodash.debounce'
import Croppie from 'croppie'
import { PageTitle, OrderForm } from '@/components'
import api from '@/helpers/api'

export default {
  components: {
    PageTitle,
    OrderForm,
  },
  created() {
    window.addEventListener('resize', debounce(this.handleResize, 100))
  },
  beforeDestroy() {
    this.destoryCroppie()
    window.removeEventListener('resize', this.handleResize)
  },
  data: () => ({
    coverImage: null,
    croppedImage: null,
    croppedThumb: null,
    step: 1,
    croppie: null,
    cropSettings: null,
    sendingOrder: false,
    messageSent: false,
    isValid: false,
  }),
  methods: {
    async loadImage(e) {
      try {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) return
        const file = files[0]
        const fileSizeValid = await this.validateMaxFileSize(file)
        const fileFormatValid = await this.validateFileFormat(file)
        if (fileSizeValid && fileFormatValid) {
          const reader = new FileReader();
          reader.onload = e => {
            this.coverImage = e.target.result
            this.step = 2
            this.initCroppie()
          }
          reader.readAsDataURL(file)
        }
      } catch (err) {
        console.log(err)
      }
    },
    refreshPage() {
      window.location.reload()
    },
    restart() {
      this.resetImage()
    },
    handleResize() {
      this.destoryCroppie()
      this.initCroppie()
    },
    resetImage() {
      this.step = 1
      this.coverImage = null
      this.croppedImage = null
      this.croppedThumb = null
      this.croppie.destroy()
    },
    getImageFromCroppie(size) {
      return this.croppie.result({
				type: 'canvas',
				size: { width: size, height: size }
			})
    },
    createImageFromEncodedImage(encodedImage) {
      return new Promise((resolve) => {
        const image = new Image()
        image.onload = () => {
          resolve(image)
        }
        image.src = encodedImage
      })
    },
    async generateCakeCover() {
      const canvas = document.getElementById('cakeCoverCanvas')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      const image = await this.createImageFromEncodedImage(this.croppedImage)
      const left = (canvas.width - image.width) / 2
      const top = (canvas.height - image.height) / 2
      ctx.drawImage(image, left, top)
      return canvas.toDataURL()
    },
    async orderSubmit({ name, email, message, phoneNumber, deliveryDate, choosenProduct, productSize, textOnCake }) {
      try {
        this.sendingOrder = true
        this.step = 4
        const image = await this.generateCakeCover()
        const { data: { orderHasBeenSent } } = await api.cakeCoverOrder({
          name,
          email,
          message,
          deliveryDate,
          phoneNumber,
          productName: choosenProduct.name,
          size: productSize.displayName,
          textOnCake,
          image,
        })
        if (orderHasBeenSent) {
          this.sendingOrder = false
          this.messageSent = true
          this.isValid = true
        }
      } catch (err) {
        this.sendingOrder = false
        this.messageSent = true
        this.isValid = false
      }
    },
    async crop() {
      this.cropSettings = this.croppie.get()
      this.croppedImage = await this.getImageFromCroppie(1588)
      this.croppedThumb = await this.getImageFromCroppie(300)
      this.step = 3
    },
    modifyCrop() {
      this.destoryCroppie()
      this.step = 2
      this.croppedImage = null
      this.croppedThumb = null
      this.initCroppie()
    },
    validateFileFormat(currentFile) {
      return new Promise((resolve, reject) => {
        const imageType = /^image\//
        if (!imageType.test(currentFile.type)) {
          reject(new Error('Ogiltig filtyp.'))
        }else{
          resolve(true)
        }
      })
    },
    validateMaxFileSize(currentFile) {
      return new Promise((resolve, reject) => {
        if(currentFile.size <= 5120000){
          resolve(true)
        } else {
          reject(new Error('Filstorleken är för stor.'))
        }
      })
    },
    getCoverElementSize(){
      const { cropcover } = this.$refs
      let currentWidth = 500
      if (cropcover) {
        currentWidth = cropcover.clientWidth
        if (window.innerWidth <= 800 && window.innerHeight <= 600) {
          currentWidth = currentWidth - 80
        }
      }
      return currentWidth
    },
    destoryCroppie() {
      if (this.croppie) {
        this.croppie.destroy()
      }
    },
    initCroppie() {
      this.$nextTick(() => {
        const elementSize = this.getCoverElementSize() - 30
        const { croppie } = this.$refs
        if (croppie) {
          this.croppie = new Croppie(croppie, {
            boundary: { width: elementSize, height: elementSize },
            viewport: { width: elementSize, height: elementSize, type: 'circle' },
            enableExif: true,
            enableOrientation: true,
            url: this.coverImage,
            points: this.cropSettings ? this.cropSettings.points : [],
          })
        }
      })
    },
  },
  metaInfo: {
    title: 'Tårta med eget motiv - Bageri & Konditori i Trollhättan - Dagmar Carlssons Hembageri Eftr.',
    description: 'Beställ tårta med eget motiv. Ladda upp en egen bild och gör beställningen direkt till oss på Dagmar Carlssons Hembageri Eftr. i Trollhättan.',
  },
}
</script>

<style lang="scss">
#cakeCoverCanvas {
  display: none;
}
</style>